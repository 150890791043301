import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { AuthStateInterface } from "../interfaces/auth-state.interface";
import { ChangeCredentials, Login, Logout, Register } from "../actions/auth.action";
import {Inject, Injectable} from "@angular/core";
import {AuthService} from "../services/auth.service";
import {UserInterface} from "../interfaces/user.interface";
import {AppHelper} from "../helpers/app.helper";
import {DOCUMENT} from "@angular/common";
import {StateClear} from "ngxs-reset-plugin";
import { MessagesService } from "../services/messages.service";
import { MessageEnum } from "../enums/message.enum";

@State<AuthStateInterface>({
  name: 'auth',
  defaults: {
    user: undefined,
  }
})
@Injectable()
export class AuthState {

  @Selector()
  static token(state: AuthStateInterface): string | undefined {
    return state.user?.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateInterface): boolean {
    return !!state.user?.token;
  }

  @Selector()
  static user(state: AuthStateInterface): UserInterface | undefined {
    return state.user;
  }

  constructor(
    private store: Store,
    private authService: AuthService,
    protected messageService: MessagesService,
    @Inject(DOCUMENT) private document: Document, 
    ) {}

  @Action(Login)
  async login(ctx: StateContext<AuthStateInterface>, action: Login) {
    const user: any = await this.authService.login({ email: action.email, password: action.password });
    if(user.status){
      this.showMessage('Bienvenido')
      ctx.setState({ user: user });
    }
  }

  @Action(Logout)
  async logout(ctx: StateContext<AuthStateInterface>, action: Logout) {
    try {
      await this.authService.logout();
      this.showMessage('Hasta luego')
    } catch (e) {
      AppHelper.DevPrint(e);
    }
    this.store.dispatch(new StateClear());
  }

  @Action(Register)
  async register(ctx: StateContext<AuthStateInterface>, action: Register) {
    const user = await this.authService.register(action.userData);
    if(user.status){
      this.showMessage('Se ha registrado correctamente.')
      ctx.setState({ 
        user: {
          ...user,
          name: "",
          lastname: "",
          dni: "",
          phone: "",
          b2b: true,
          b2bStatus: "",
          admin: false,
          id_client: null
        }
      });
    }
  }

  showMessage(message: string){
    this.messageService.add({
      type: MessageEnum.SUCCESS,
      content: message,
    });
  }

}
