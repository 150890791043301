import { MarketplaceDataInterface } from "../app/shared/interfaces/marketplace-data.interface";

export const MarketplaceData: MarketplaceDataInterface = {
    "service": {
        "token": "rIH99Wc_HcMlonQTugzKybVcUHlJMglDa8f8Y7lAAwf7qaL_5FQyDVmYFU6pHeqoBdbxluAx417y2jn2QkzNqw",
        "url": "https://ds.deepcompany.com/marketplace"
    },
    "marketplace": {
        "name": "Tiendas UPI",
        "description": "Repuesto a Domicilio - Somos tu mejor aliado a la hora de adquirir un repuesto para tu vehículo, contamos con una amplia variedad de repuestos para automóviles con un servicio de excelencia.",
        "environment": "live",
        "version": "0.1",
        "paymentMethods": {
            "Transbank": {}
        },
        "currency": {
            "name": "Pesos chilenos",
            "symbol": "CLP"
        },
    },
    "template": {
        "id": "default",
        "name": "Template default (Deepyshop)",
        "modules": [
            "BasicContainer",
            "GridContainer",
            "HtmlEditor",
            "LoginButton",
            "RegisterButton",
            "GroupSliderNav",
            "TabNav",
            "ProductList",
            "AddressButton",
            "Logo",
            "CartList",
            "OrderList",
            "OrderDetail",
            "ProductDetail",
            "Table",
            "Profile",
            "Table",
            "FavoriteList",
            //"BreadCump"
        ],
        "cssVariables": [
            {
                "name": "font-name",
                "label": "Nombre estilo de letras",
                "description": "Nombre del font-style correspondiente a la url de la fuente a usar."
            },
            {
                "name": "bg-color",
                "label": "Color de background principal",
                "description": "Color de fondo para la página."
            },
            {
                "name": "primary-color",
                "label": "Color Principal",
                "description": "Color principal que se muestra en el header."
            },
            {
                "name": "complement-color",
                "label": "Color complementario",
                "description": "Color que complementa el color principal."
            },
            {
                "name": "text-color",
                "label": "Color de texto",
                "description": "Color de texto por default (contraste del color de fondo principal)."
            },
            {
                "name": "text-complement-color",
                "label": "Color texto complementario",
                "description": "Color de texto irrelevante como placeholders."
            },
            {
                "name": "text-contrast-color",
                "label": "Color texto contraste",
                "description": "Color de texto para fondos con color principal o secundario."
            },
            {
                "name": "space-size",
                "label": "Tamaño de espacios",
                "description": "Tamaño base para paddings, gap y margins."
            }
        ],
        "styles": [],
        "scripts": [],
        "globalElements": [
            {
                "name": "header",
                "label": "Header",
                "description": "Encabezado de la página"
            },
            {
                "name": "footer",
                "label": "Footer",
                "description": "Pie de página"
            },
            {
                "name": "loader",
                "label": "Loader FullScreen",
                "description": "Loader que se muestra cuando se accede a una página o se consume una url de la api."
            },
            {
                "name": "float_panel",
                "label": "Panel Flotante",
                "description": "Panel flotante de posición absoluta que muestran en todas las páginas."
            }
        ]
    },
    "elementsConfig": {
        "BasicContainer": {
            "id": "BasicContainer",
            "name": "Contenedor Básico",
            "isContainer": true,
            "config": {}
        },
        "GridContainer": {
            "id": "GridContainer",
            "name": "Contenedor Grid",
            "isContainer": true,
            "config": {}
        },
        "HtmlEditor": {
            "id": "HtmlEditor",
            "name": "Editor HTML",
            "isContainer": false,
            "config": {}
        },
        "imageHeader": {
            "id": "ImageHeader",
            "name": "Image Header",
            "isContainer": false,
            "config": {
                "logoUrl": "",
                "classes": {
                    "logo": 'height: 76px; width: 180px;'
                }
            }
        },
        "LoginButton": {
            "id": "LoginButton",
            "name": "Login Button",
            "isContainer": false,
            "config": {
                "label": "",
                "classes": {
                    "form-button": "",
                    "modal":""
                }
            }
        },
        "OrderDetail": {
            "id": "OrderDetail",
            "name": "Detalle de la orden",
            "isContainer": false,
            "config": {}
        },
        "SocialMedia": {
            "id": "SocialMedia",
            "name": "Social media WS",
            "isContainer": false,
            "config": {}
        },
        "FavoriteList": {
            "id": "FavoriteList",
            "name": "Favoritos",
            "isContainer": false,
            "config": {}
        },
        "RegisterButton": {
            "id": "RegisterButton",
            "name": "Register Button",
            "isContainer": false,
            "config": {
                "label": "",
                "classes": {
                    'form-button': 'padding: 10px; margin:10px; color:red; backgroud-color: blue',
                    'modal':'',

                }
            }
        },
        "GroupSliderNav": {
            "id": "GroupSliderNav",
            "name": "Group Slider Navigation",
            "isContainer": false,
            "config": {
            }
        },
        "ProductDetail": {
            "id": "ProductDetail",
            "name": "Product Detail",
            "isContainer": true,
            "config": {

            }
        },
        "TabNav": {
            "id": "TabNav",
            "name": "Tab Navigation",
            "isContainer": true,
            "config": {
            }
        },
        "Profile": {
            "id": "Profile",
            "name": "Profile",
            "isContainer": true,
            "config": {
            }
        },
        "ProductList": {
            "id": "ProductList",
            "name": "Lista de Producto",
            "isContainer": false,
            "config": {
            }
        },
        "CartList": {
            "id": "CartList",
            "name": "Lista de carro de compra",
            "isContainer": false,
            "config": {
            }
        },
        "AddressButton": {
            "id": "AddressButton",
            "name": "Agregar Dirección",
            "isContainer": false,
            "config": {
            }
        },
        "Table": {
            "id": "Table",
            "name": "Table",
            "isContainer": false,
            "config": {
            }
        },
        "Logo": {
            "id": "Logo",
            "name": "Logo",
            "isContainer": false,
            "config": {
                "logoUrl": "",
                "classes":{
                    "logo": ""
                }
            }
        },
        "OrderList": {
            "id": "OrderList",
            "name": "Listado de ordenes",
            "isContainer": false,
            "config": {
            }
        },
        "BreadCump": {
            "id": "BreadCump",
            "name": "Bread Cump",
            "isContainer": false,
            "config": {
            }
        },
        "SliderPromo": {
            "id": "SliderPromo",
            "name": "Slider Promo",
            "isContainer": true,
            "config": {
            }
        }, 
    },
    "pages": [
        //   HEADER PAGE
        {
            "id": "659f204eb58b2e835091bcfe",
            "title": "Header",
            "slug": "header",
            "global": "header",
            "elements": [
                {
                    // RESPONSIVE HEADER
                    "module" : "GridContainer",
                    "value" : {
                        "responsive": {
                            // Number of columns (max: 12, min: 0) by children for each breakpoint (xs, md, lg, xl). Default: 12
                            "xs": [ 6, 0, 6, 12 ],
                            "md": [ 1, 7, 4, 0 ],
                        }
                    },
                    "children" : [
                        // HEADER LOGO
                        {
                            "module": "Logo",
                            "value": {
                                "logoUrl": "https://piwigo.deepyshop.com/upload/2022/10/06/20221006135859-9533412c.png",
                                "classes": {
                                    "logo": "max-width: 6rem"
                                }
                            },
                            "children": [],
                        },

                        // HEADER TITLE - LG
                        {
                            "module": "HtmlEditor",
                            "value": {
                                "html": "<h1 style='padding: 0 2rem;color: #FFFFBE; font-size: 1.2rem;font-weight: 300'>Todos los repuestos que tu auto necesita</h1>"
                            },
                            "children": [],
                        },

                        // SESSION OPTIONS
                        {
                            "module": "GridContainer",
                            "value": {
                                "responsive": {
                                    "xs": [ 12, 12 ],
                                    "md": [ 6, 6],
                                }
                            },
                            "children": [
                                {
                                    "module": "RegisterButton",
                                    "value": {
                                        "label": "Registrarse",
                                    },
                                    "classes": {
                                        "host": "display:flex;padding:0.25rem"
                                    },
                                    "children": [],
                                },
                                {
                                    "module": "LoginButton",
                                    "value": {
                                        "label": "Iniciar sesión",
                                        "logoutLabel": "Cerrar sesión"
                                    },
                                    "classes": {
                                        "host": "display:flex;padding:0.25rem"
                                    },
                                    "children": [],
                                },
                            ],
                        },

                        // HEADER TITLE - XS
                        {
                            "module": "HtmlEditor",
                            "value": {
                                "html": "<h1 style='border-top: 1px solid #fff;text-align: center; padding: 1rem 2rem 0;color: #FFFFBE; font-size: 1rem;font-weight: 300'>Todos los repuestos que tu auto necesita</h1>"
                            },
                            "children": [],
                        }

                    ],
                }
            ]
        },

        // FOOTER PAGE
        {
            "id": "659f2436b58b2e835091bdb9",
            "title": "Footer",
            "slug": "footer",
            "global": "footer",
            "elements": [
                {
                    "_id": "65a057bc10764b4e7ea2b271",
                    "name": "Footer Text",
                    "module": "HtmlEditor",
                    "value": {
                        "html": "<div style=\"text-align:center;color:var(--text-primary);font-size: 0.8rem\">" +
                            "Repuesto a Domicilio | RUT:76.871.154-2 | Potenciado por Deepcompany LLC " +
                            "Copyright ©2024</div>"
                    },
                    "children": [],
                }
            ]
        },

        // LOADER FULL SCREEN
        {
            "id": "659f243ab58b2e835091bdc4",
            "title": "Loader FullScreen",
            "slug": "loader-fullscreen",
            "global": "loader",
            "elements": [
                {
                    "_id": "65a057bc10764b4e7ea2b27b",
                    "name": "Loader - image",
                    "module": "HtmlEditor",
                    "value": {
                        "html": "<div id=\"tachometer\">\n                  <div class=\"ii\">\n                      <div><b><span class=\"num_1\">0</span></b></div>\n                      <div><b></b></div>\n                      <div><b><span class=\"num_2\">1</span></b></div>\n                      <div><b></b></div>\n                      <div><b><span class=\"num_3\">2</span></b></div>\n                      <div><b></b></div>\n                      <div><b><span class=\"num_4\">3</span></b></div>\n                      <div><b></b></div>\n                      <div><b><span class=\"num_5\">4</span></b></div>\n                      <div><b></b></div>\n                      <div><b><span class=\"num_6\">5</span></b></div>\n                      <div><b></b></div>\n                      <div><b><span class=\"num_7\">6</span></b></div>\n                      <div><b></b></div>\n                      <div><b><span class=\"num_8\">7</span></b></div>\n                      <div><b></b></div>\n                      <div><b><span class=\"num_9\">8</span></b></div>\n                  </div> \n                <div id=\"redline\"></div>\n          <div class=\"line\"></div>\n         <div class=\"pin\"><div class=\"inner\"></div></div> \n    </div>"
                    },
                    "page": "659f243ab58b2e835091bdc4",
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ea2b27b"
                }
            ]
        },

        // FLOAT PANEL
        {
            "id": "659f243db58b2e835091bdcf",
            "title": "Panel Flotante",
            "slug": "panel-flotante",
            "global": "float_panel",
            "elements": [
            ]
        },

        // Official pages
        {
            "id": "659f42fc8c20ef9159d17c4a",
            "title": "Home",
            "slug": "",
            "global": null,
            "elements": [
                {
                    "module": "TabNav",
                    "value": {
                        roles: [ 'guest', 'auth', 'auth', 'auth', 'auth' ],
                        slugs: [ 'home', 'wish', 'carrito', 'perfil', 'orders' ]
                    },
                    "classes": {
                        "host": "display:inline-block;width:100%"
                    },
                    "children": [
                        {
                            "module" : "BasicContainer",
                            "value" : {
                                "name" : "Catálogo"
                            },
                            "children" : [
                                {
                                    "module": "GroupSliderNav",
                                    "value": {
                                        // Used it for event system - Enviar
                                        "sender_scope": "product-list-category"
                                    },
                                    "classes": {
                                        "host": "display:block;padding:3rem 0 0;"
                                    },
                                    "children": [],
                                },
                                {
                                    "module": "ProductList",
                                    "value": {
                                        "productPage": "659f42fc8c20ef9159d17c4b",
                                        // Used it for event system - Recibir
                                        "scope": "product-list-category"
                                    },
                                    "children": [],
                                },
                            ],
                        },
                        {
                            "module" : "BasicContainer",
                            "value" : {
                                "name" : "Lista de favoritos",
                            },
                            "children" : [
                                {
                                    "module" : "FavoriteList",
                                    "value" : {},
                                    "children" : [],
                                }
                            ],
                        },
                        {
                            "module" : "BasicContainer",
                            "value" : {
                                "name" : "Carrito",
                            },
                            "children" : [
                                {
                                    "module" : "CartList",
                                    "value" : {},
                                    "children" : [],
                                }
                            ],
                        },
                        {
                            "module" : "BasicContainer",
                            "value" : {
                                "name" : "Perfil",
                            },
                            "children" : [
                                {
                                    "module" : "Profile",
                                    "value" : {},
                                    "children" : [
                                        {
                                            "_id": "65a057bc10764b47b",
                                            "name": "Address Button",
                                            "module": "AddressButton",
                                            "value": {
                                                "label": "+ Agregar dirección",
                                                "classes": {
                                                    "add-address-btn": "background-color: var(--primary-color);color: white;border-radius: 10px;font-size: 10px;font-weight: 300;padding: 2px 8px;",
                                                    'modal':'',
                                                }
                                            },
                                            "parent": null,
                                            "children": [],
                                            "isDeleted": false,
                                            "deletedAt": null,
                                            "createdAt": "2024-01-11T21:03:56.905Z",
                                            "updatedAt": "2024-01-11T21:15:26.441Z",
                                            "id": "65a057bc10764b4e7ea2b27b"
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            "module" : "BasicContainer",
                            "value" : {
                                "name" : "Ordenes",
                            },
                            "children" : [
                                {
                                    "module" : "OrderList",
                                    "value" : {
                                    },
                                    "children" : [],
                                }
                            ],
                        }
                    ],
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Slider Promo",
                    "module": "SliderPromo",
                    "value": {},
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10789b4e7ea2b29i"
                },
            ]
        },

        // Product Page
        {
            "id": "659f42fc8c20ef9159d17c4b",
            "title": "Product Detail",
            "slug": "product",
            "global": null,
            "elements": [
                {
                    "module": "TabNav",
                    "value": {
                        roles: [ 'guest', 'guest' ],
                        slugs: [ 'product_details', 'compatibilities' ]
                    },
                    "classes": {
                        "host": "display:inline-block;width:100%"
                    },
                    "children": [
                        {
                            "module" : "BasicContainer",
                            "value" : {
                                "name" : "Detalle del producto"
                            },
                            "classes": {
                                "host": "padding: 1rem 1rem;display:block"
                            },
                            "children" : [
                                {
                                    "module": "ProductDetail",
                                    "value": {
                                    },
                                    "children": [],
                                },
                            ],
                        },
                        {
                            "module" : "BasicContainer",
                            "value" : {
                                "name" : "Compatibilidades",
                            },
                            "classes": {
                                "host": "display:inline-block;padding:1rem;width:100%"
                            },
                            "children" : [
                                {
                                    "module": "Table",
                                    "name": "Compatibilidades",
                                    "value": {
                                        "path": "product.compatibilities",
                                        "pagination": 20,
                                        "fields": [
                                            { "label": "Marca", "field": "mark" },
                                            { "label": "Modelo", "field": "model" },
                                            { "label": "Tipo", "field": "type" },
                                            { "label": "Tamaño", "field": "size" },
                                            { "label": "Desde", "field": "from" },
                                        ]
                                    },
                                    "classes": {},
                                    "children": [],
                                },
                            ],
                        },
                    ],
                }
            ]
        },

        // LIBRARY COMPONENTS FOR TEMPLATE <- REMOVE ON DEPLOY
        {
            "id": "659f42fc8c20ef9159d17c4f",
            "title": "Library",
            "slug": "library",
            "global": null,
            "elements": [
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Image Header",
                    "module": "ImageHeader",
                    "value": {
                        "logoUrl": "https://piwigo.deepyshop.com/upload/2022/10/06/20221006135859-9533412c.png",
                        "classes": {
                            "logo": 'height: 76px; width: 180px;',
                        }
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10789b4e7ea2b29i"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Logo",
                    "module": "Logo",
                    "value": {
                        "logoUrl": "https://piwigo.deepyshop.com/upload/2022/10/06/20221006135859-9533412c.png",
                        "classes": {
                            "logo": "height: 76px; width: 180px;"
                        }
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ea2b27b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Detalle de la orden",
                    "module": "OrderDetail",
                    "value": {},
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ea2b27b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Login Button",
                    "module": "LoginButton",
                    "value": {
                        "label": "Iniciar sesión",
                        "classes": {
                            'login-button': '',
                            'modal':'',
                        }
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ea2b27b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Register Button",
                    "module": "RegisterButton",
                    "value": {
                        "label": "Registrarse",
                        "classes": {
                            'register-button': 'padding: 10px; margin:10px; color:red; backgroud-color: blue',
                            'modal':'',
                        }
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ej2b87b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Address Button",
                    "module": "AddressButton",
                    "value": {
                        "label": "+ Agregar dirección",
                        "classes": {
                            'login-button': '',
                            'modal':'',
                        }
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ea2b27b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Navegación por categoría",
                    "module": "GroupSliderNav",
                    "value": {
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ej2b87b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Product Detail",
                    "module": "ProductDetail",
                    "value": {},
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ea2b27b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Table",
                    "module": "Table",
                    "value": {
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ea2b27b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Navegación por tabs",
                    "module": "TabNav",
                    "value": {},
                    "parent": null,
                    "children": [
                        {
                            "_id" : "id_tab_1",
                            "id" : "id_tab_1",
                            "name" : "Catálogo",
                            "module" : "BasicContainer",
                            "value" : {},
                            "page" : "659f42fc8c20ef9159d17c4f",
                            "parent" : null,
                            "children" : [
                                {
                                    "_id" : "65b08d6d9282380a2e54daaf",
                                    "name" : "Contenido",
                                    "module" : "HtmlEditor",
                                    "value" : {
                                        "html" : "<h1>Contenido de Catálogo</h1>"
                                    },
                                    "page" : "659f2436b58b2e835091bdb9",
                                    "parent" : null,
                                    "children" : [],
                                    "isDeleted" : true,
                                    "deletedAt" : null,
                                    "createdAt": "2024-01-11T21:03:56.905Z",
                                    "updatedAt": "2024-01-11T21:15:26.441Z",
                                }
                            ],
                            "isDeleted" : false,
                            "deletedAt" : null,
                            "createdAt": "2024-01-11T21:03:56.905Z",
                            "updatedAt": "2024-01-11T21:15:26.441Z",
                        },
                        {
                            "_id" : "id_tab_2",
                            "id" : "id_tab_2",
                            "name" : "Favorito",
                            "module" : "BasicContainer",
                            "value" : {},
                            "page" : "659f42fc8c20ef9159d17c4f",
                            "parent" : null,
                            "children" : [
                                {
                                    "_id" : "65b08d6d9282380a2e54daaf",
                                    "name" : "Contenido",
                                    "module" : "HtmlEditor",
                                    "value" : {
                                        "html" : "<h1>Contenido de Favorito</h1>"
                                    },
                                    "page" : "659f2436b58b2e835091bdb9",
                                    "parent" : null,
                                    "children" : [],
                                    "isDeleted" : true,
                                    "deletedAt" : null,
                                    "createdAt": "2024-01-11T21:03:56.905Z",
                                    "updatedAt": "2024-01-11T21:15:26.441Z",
                                }
                            ],
                            "isDeleted" : false,
                            "deletedAt" : null,
                            "createdAt": "2024-01-11T21:03:56.905Z",
                            "updatedAt": "2024-01-11T21:15:26.441Z",
                        },
                        {
                            "_id" : "id_tab_3",
                            "id" : "id_tab_3",
                            "name" : "Carrito",
                            "module" : "BasicContainer",
                            "value" : {},
                            "page" : "659f42fc8c20ef9159d17c4f",
                            "parent" : null,
                            "children" : [
                                {
                                    "_id" : "65b08d6d9282380a2e54daaf",
                                    "name" : "Contenido",
                                    "module" : "HtmlEditor",
                                    "value" : {
                                        "html" : "<h1>Contenido de Carrito</h1>"
                                    },
                                    "page" : "659f2436b58b2e835091bdb9",
                                    "parent" : null,
                                    "children" : [],
                                    "isDeleted" : true,
                                    "deletedAt" : null,
                                    "createdAt": "2024-01-11T21:03:56.905Z",
                                    "updatedAt": "2024-01-11T21:15:26.441Z",
                                }
                            ],
                            "isDeleted" : false,
                            "deletedAt" : null,
                            "createdAt": "2024-01-11T21:03:56.905Z",
                            "updatedAt": "2024-01-11T21:15:26.441Z",
                        },
                        {
                            "_id" : "id_tab_4",
                            "id" : "id_tab_4",
                            "name" : "Perfil",
                            "module" : "BasicContainer",
                            "value" : {},
                            "page" : "659f42fc8c20ef9159d17c4f",
                            "parent" : null,
                            "children" : [
                                {
                                    "_id" : "65b08d6d9282380a2e54daaf",
                                    "name" : "Contenido",
                                    "module" : "HtmlEditor",
                                    "value" : {
                                        "html" : "<h1>Contenido de Perfil</h1>"
                                    },
                                    "page" : "659f2436b58b2e835091bdb9",
                                    "parent" : null,
                                    "children" : [],
                                    "isDeleted" : true,
                                    "deletedAt" : null,
                                    "createdAt": "2024-01-11T21:03:56.905Z",
                                    "updatedAt": "2024-01-11T21:15:26.441Z",
                                }
                            ],
                            "isDeleted" : false,
                            "deletedAt" : null,
                            "createdAt": "2024-01-11T21:03:56.905Z",
                            "updatedAt": "2024-01-11T21:15:26.441Z",
                        },
                        {
                            "_id" : "id_tab_5",
                            "id" : "id_tab_5",
                            "name" : "Ordenes",
                            "module" : "BasicContainer",
                            "value" : {},
                            "page" : "659f42fc8c20ef9159d17c4f",
                            "parent" : null,
                            "children" : [
                                {
                                    "_id" : "65b08d6d9282380a2e54daaf",
                                    "name" : "Contenido",
                                    "module" : "HtmlEditor",
                                    "value" : {
                                        "html" : "<h1>Contenido de Ordenes</h1>"
                                    },
                                    "page" : "659f2436b58b2e835091bdb9",
                                    "parent" : null,
                                    "children" : [],
                                    "isDeleted" : true,
                                    "deletedAt" : null,
                                    "createdAt": "2024-01-11T21:03:56.905Z",
                                    "updatedAt": "2024-01-11T21:15:26.441Z",
                                }
                            ],
                            "isDeleted" : false,
                            "deletedAt" : null,
                            "createdAt": "2024-01-11T21:03:56.905Z",
                            "updatedAt": "2024-01-11T21:15:26.441Z",
                        }
                    ],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ej2b87b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Lista de Productos",
                    "module": "ProductList",
                    "value": {
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10764b4e7ea2b27b"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Social Media",
                    "module": "SocialMedia",
                    "value": {
                        "socialMedias": [
                            {
                                "type": "ws",
                                "phone": "56941554451",
                                "url": ""
                            }
                        ],
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10789b4e7ea2b29i"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Bread Cump",
                    "module": "BreadCump",
                    "value": {
                        "breadCumpData": [
                            {
                                "name": "Categoría",
                                "children": [
                                    {
                                        "name": "Subcategoria 1",
                                        "url": "/"
                                    },
                                    {
                                        "name": "Subcategoria 2",
                                        "url": "/"
                                    }
                                ],
                                "url": "/"
                            }
                        ],
                    },
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10789b4e7ea2b29i"
                },
                {
                    "_id": "65a057bc10764b47b",
                    "name": "Slider Promo",
                    "module": "SliderPromo",
                    "value": {},
                    "parent": null,
                    "children": [],
                    "isDeleted": false,
                    "deletedAt": null,
                    "createdAt": "2024-01-11T21:03:56.905Z",
                    "updatedAt": "2024-01-11T21:15:26.441Z",
                    "id": "65a057bc10789b4e7ea2b29i"
                },
            ]
        },
    ]
}
