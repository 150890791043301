import { MarketplaceDataInterface } from "../app/shared/interfaces/marketplace-data.interface";
import {MarketplaceData} from "./marketplace_data";

const marketplaceData: MarketplaceDataInterface = MarketplaceData;

export const environment: Partial<MarketplaceDataInterface> & {[key: string]: any} = {
  env: marketplaceData.marketplace.environment,
  marketplace: marketplaceData.marketplace,
  service: marketplaceData.service,
  template: marketplaceData.template,
  pages: (marketplaceData.pages || []).filter((p: any) => !p.global),
  elements: (marketplaceData.pages || []).filter((p: any) => p.global),
};
