import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  constructor(
    protected requestService: RequestService
  ) { }

  list(): Promise<any> {
    return this.requestService.get('product', {
      ignoreToken: false,
      query: `list=favorites`
    });
  }

  add(id: string): Promise<{status: boolean, message: string}> {
    return this.requestService.post('favorite',{
      ignoreToken: false,
      params: {id}
    });
  }

  delete(id: string): Promise<{status: boolean, message: string}>{
    return this.requestService.delete('favorite', {
      ignoreToken: false,
      query: `id=${id}`
    })
  }

}
