import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {EntityStateInterface} from "../interfaces/entity-state.interface";
import {ProductInterface} from "../interfaces/product.interface";
import {ProductService} from "../services/product.service";
import {EntityAdd, EntityById, EntityDelete, EntityGetList} from '../actions/entity.action';
import {OrdersService} from "../services/orders.service";
import {OrderInterface} from "../interfaces/order.interface";
import {VehicleService} from "../services/vehicle.service";
import {VehicleInterface} from "../interfaces/vehicle.interface";
import {CartService} from "../services/cart.service";
import {CartResponseInterface} from "../interfaces/cart-product.interface";
import {AddressResponseInterface} from "../interfaces/address.interface";
import {AddressService} from "../services/address.service";
import {EntityEnum} from "../enums/entity.enum";
import {MessagesService} from "../services/messages.service";
import {MessageEnum} from "../enums/message.enum";
import { FavoriteService } from "../services/favorite.service";
import { faV } from "@fortawesome/free-solid-svg-icons";

@State<EntityStateInterface>({
  name: 'entity',
  defaults: {
    product: undefined,
    orders: undefined,
    vehicles: [],
    addresses: [],
    address: undefined,
    cart: undefined,
    favorites: []
  }
})
@Injectable()
export class EntityState {


  @Selector()
  static product(state: EntityStateInterface): ProductInterface | undefined {
    return state.product;
  }
  @Selector()
  static orders(state: EntityStateInterface): OrderInterface | undefined {
    return state.orders;
  }
  @Selector()
  static cart(state: EntityStateInterface): CartResponseInterface | undefined{
    return state.cart;
  }
  @Selector()
  static vehicles(state: EntityStateInterface): VehicleInterface[] | undefined {
    return state.vehicles;
  }
  @Selector()
  static addresses(state: EntityStateInterface): AddressResponseInterface[] | undefined {
    return state.addresses;
  }
  @Selector()
  static address(state: EntityStateInterface): AddressResponseInterface | undefined {
    return state.address;
  }
  @Selector()
  static favorites(state: EntityStateInterface): any[] | [] {
    return state.favorites;
  }

  constructor(
    private productService: ProductService,
    private orderService: OrdersService,
    private vehicleService: VehicleService,
    private cartService: CartService,
    private addressService: AddressService,
    private messagesService: MessagesService,
    protected messageService: MessagesService,
    protected favoriteService: FavoriteService
  ) {}

  @Action(EntityById)
  async entityById(ctx: StateContext<EntityStateInterface>, action: EntityById) {
    const state = ctx.getState();
    let entity = undefined;
    switch (action.type) {
      case EntityEnum.Product:
        if (action.id) {
          entity = await this.productService.getProduct(action.id);
        }
        ctx.setState({
          ...state,
          product: entity
        });
    }
  }

  @Action(EntityAdd)
  async entityAdd(ctx: StateContext<EntityStateInterface>, action: EntityAdd) {
    const state = ctx.getState();
    let entity = undefined;
    switch (action.type) {
      case EntityEnum.Favorites:
        entity = await this.favoriteService.add(action.payload._id);
        if(entity.status) {
          this.showMessage(entity.message)
          const {favorites} = state;
          const item = {...action.payload, image: {image: action.payload.image}}
          ctx.patchState({favorites: [...favorites, item] }) 
        };
        break;
      case EntityEnum.Addresses:
        entity = await this.addressService.put(action.payload);
        this.showMessage('Dirección agregada');
        (entity.status) && ( ctx.patchState({ address: undefined }) );
        break;
      case EntityEnum.Address:
          ctx.setState({
            ...state,
            address: action.payload
          })
          break;
      case EntityEnum.Cart:
        const {id, qty} = action.payload;
        entity = await this.cartService.add(id, qty);
        this.showMessage('Item agregado al carrito');
        ctx.setState({
            ...state,
            cart: entity
        });
        break;
      case EntityEnum.Vehicles:
        const vehicle = {
          brand: action.payload.make,
          year: action.payload.year,
          model: action.payload.model,
          motor: action.payload._id,
        };
        try {
          await this.vehicleService.add(vehicle);
          await this.messagesService.add({
            type: MessageEnum.SUCCESS,
            content: 'Vehiculo guardado satisfactoriamente.'
          });
          await this.entityList(ctx, new EntityGetList(EntityEnum.Vehicles, {}))
        } catch (e: any) {
          await this.messagesService.add({
            type: MessageEnum.SUCCESS,
            content: e.message
          });
        }
        break;
      default:
        break;
    }
  }

  @Action(EntityGetList)
  async entityList(ctx: StateContext<EntityStateInterface>, action: EntityGetList) {
    const state = ctx.getState();
    let entity = undefined;

    switch (action.type) {
      case EntityEnum.Favorites:
        entity =  await this.favoriteService.list();
        ctx.patchState({
          favorites: entity.results
        })
        break
      case EntityEnum.Vehicles:
        entity = await this.vehicleService.list();
        ctx.patchState({
           vehicles: entity
        });
        break;
      case EntityEnum.Addresses:
        entity = await this.addressService.list();
        ctx.patchState({
          addresses: entity
        });
        break;
      case EntityEnum.Cart:
        entity = await this.cartService.list();
        ctx.patchState({
          cart: entity
        })
        break
      case EntityEnum.Orders:
        if(action.params){
          entity = await this.orderService.getOrderList(action.params);
        }
        ctx.patchState( {
           orders: entity
        });
        break;
        default:
          break;
    }
  }

  @Action(EntityDelete)
  async entityDelete(ctx: StateContext<EntityStateInterface>, action: EntityDelete) {
    const state = ctx.getState();
    let entity = undefined;
    switch (action.type) {
      case EntityEnum.Favorites:
        const { favorites } = state;
        entity = await this.favoriteService.delete(action.id);
        if (entity.status) {
          this.showMessage(entity.message);
          const index = favorites!.findIndex(item => item._id === action.id);
          favorites?.splice(index, 1)
          ctx.setState({
            ...state,
            favorites
          });
        }

        break;
      case EntityEnum.Addresses:
        const { addresses } = state;
        entity = await this.addressService.delete(action.id);
        this.showMessage('Dirección eliminada');
        if (entity.status) {
          const index = addresses!.findIndex(item => item._id === action.id);
          addresses?.splice(index, 1)
          ctx.setState( {
            ...state,
            addresses
          });
        }
        break;
      case EntityEnum.Address:
        ctx.patchState({
          address: undefined
        })
        break;
      case EntityEnum.Cart:
      entity = await this.cartService.delete(action.id, action.cartId);
      this.showMessage('Item eliminado del carrito');
      ctx.setState({
          ...state,
          cart: entity
      });
        break;
      case EntityEnum.Vehicles:
        const { vehicles } = state;
        entity = await this.vehicleService.delete(action.id)
        if(entity.status){
          const index = vehicles!.findIndex(item => item._id === action.id);
          vehicles?.splice(index, 1)
        }
        ctx.setState( {
          ...state,
          vehicles
        });
          break;
      default:
        break;
    }
  }

  showMessage(message: string){
    this.messageService.add({
      type: MessageEnum.SUCCESS,
      content: message,
    });
  }


}
