import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import {ItemInterface, StorageStateInterface} from "../interfaces/storage.state.interface";
import { StorageEnum } from "../enums/storage.enum";
import { AddressResponseInterface } from "../interfaces/address.interface";
import { StorageAdd, StorageDelete, StorageUpdate } from "../actions/storage.action";
import { MessagesService } from "../services/messages.service";
import { MessageEnum } from "../enums/message.enum";
@State<StorageStateInterface>({
    name: 'storage',
    defaults: {
      favorite: [],
      address: undefined,
      cartAddress: undefined,
    }
  })

@Injectable()
export class StorageState {

  constructor(
    protected messageService: MessagesService
  ){}

    @Selector()
    static favorites(state: StorageStateInterface): ItemInterface[] | []{
      return state.favorite;
    }


    @Selector()
    static address(state: StorageStateInterface): AddressResponseInterface | undefined{
      return state.address;
    }

    @Selector()
    static cartAddress(state: StorageStateInterface): any | undefined{
      return state.cartAddress;
    }


    @Action(StorageAdd)
    add(ctx: StateContext<StorageStateInterface>, action: StorageAdd) {
        const state = ctx.getState();
        let index;
        switch (action.type) {
          case StorageEnum.cartAddress:
            ctx.setState({
              ...state,
              cartAddress: action.payload
            });
            break;
          case StorageEnum.Favorites:
            index = state.favorite.findIndex( (item: ItemInterface) => item.product?._id === action.payload?.product?._id);
            if(index=== -1){
              this.showMessage('Item agregado a favorito')
              ctx.setState({
                ...state,
                favorite: [...state.favorite, action.payload] as any
              });
            } else {
              this.showMessage('Item ya existe en favorito')
            }
            break;
          case StorageEnum.Address:
            this.showMessage('Dirección agregada')
            ctx.setState({
              ...state,
              address: action.payload
            })
            break;
          default:
            break;
        }

    }

    @Action(StorageDelete)
    delete(ctx: StateContext<StorageStateInterface>, action: StorageDelete) {
      const state = ctx.getState();
      let index;
      switch (action.type){
        case StorageEnum.Favorites:
          index = state.favorite.findIndex( (item) => item.product?._id === action.id);
          state.favorite.splice(index, 1);
          this.showMessage('Item eliminado');
          ctx.setState({
           ...state
          })
          break;
        case StorageEnum.Address:
          this.showMessage('Dirección ha sido eliminada');
          ctx.patchState({
            address: undefined
          })
          break;
        default:
          break;

      }
    }

    @Action(StorageUpdate)
    update(ctx: StateContext<StorageStateInterface>, action: StorageUpdate) {
      const {favorite} = ctx.getState();
      switch (action.type){
        case StorageEnum.Favorites:
          Object.values( favorite ).forEach( ( v ) => {
            if ( v.product?._id === action.payload?.product?._id ) {
              v.qty = action.payload?.qty as number;
            }
          })
          this.showMessage('Item actualizado');
          ctx.setState({
            ...ctx.getState(),
            favorite
          });
          break;
        default:
          break;

      }
    }

    showMessage(message: string){
      this.messageService.add({
        type: MessageEnum.SUCCESS,
        content: message,
      });
    }

}
