import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
import { LazyModuleLoader } from "../shared/services/lazy-module-loader";

@Component({
  selector: 'app-base-element',
  template: '<ng-template #element_component></ng-template>',
  host: {
    style: 'height:min-content;display:block'
  }
})
export class BaseElementComponent {
  @Input()
  element: any;

  _elementContainer: ViewContainerRef | undefined;

  @ViewChild('element_component', { read: ViewContainerRef })
  set elementContainer(elC: ViewContainerRef | undefined) {
    this._elementContainer = elC;
    this.loadElementComponent();
  }

  get elementContainer(): ViewContainerRef | undefined {
    return this._elementContainer;
  }

  constructor(protected lazyModuleLoader: LazyModuleLoader) {}

  async loadElementComponent() {
    try {
      this.elementContainer?.clear();
      if (!this.element?.module) {
        return;
      }

      await this.lazyModuleLoader.loadComponent(this.elementContainer,
        this.element?.module,
        {
          element: this.element
        }, {
        });
    } catch (e) {
      console.log(e);
    }
  }
}
