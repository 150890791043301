import {RegisterUser} from "../interfaces/register-user.interface";

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public email: string, public password: string) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor() {}
}

export class Register {
  static readonly type = '[Auth] Register';
  constructor(public userData: RegisterUser) {}
}
export class ChangeCredentials {
  static readonly type = '[Auth] ChangeCredentials';
  constructor(public userData: RegisterUser) {}
}

