import { ShippingAddressFormModule } from './shipping-address-form/shipping-address-form.module';
import { SocialMediaModule } from './social-media/social-media.module';

export const LazyModRegistered: any = {
  // MARKETPLACE LAYERS
  default: () => import('./layers/default-layer/default-layer.module').then(m => m.DefaultLayerModule),
  // MARKETPLACE ELEMENTS
  BasicContainer: () => import('./basic-container/basic-container.module').then(m => m.BasicContainerModule),
  GridContainer: () => import('./grid-container/grid-container.module').then(m => m.GridContainerModule),
  HtmlEditor: () => import('./html-editor/html-editor.module').then(m => m.HtmlEditorModule),
  LoginButton: () => import('./login-button/login-button.module').then(m => m.LoginButtonModule),
  RegisterButton: () => import('./register-button/register-button.module').then(m => m.RegisterButtonModule),
  GroupSliderNav: () => import('./group-slider-nav/group-slider-nav.module').then(m => m.GroupSliderNavModule),
  TabNav: () => import('./tab-nav/tab-nav.module').then(m => m.TabNavModule),
  ProductList: () => import('./product-list/product-list.module').then(m => m.ProductListModule),
  AddressButton: () => import('./address-button/address-button.module').then(m => m.AddressButtonModule),
  Logo: () => import('./logo/logo.module').then( m => m.LogoModule),
  CartList: () => import('./cart-list/cart-list.module').then(m => m.CartListModule),
  OrderList: () => import('./order-list/order-list.module').then(m => m.OrderListModule),
  OrderDetail: () => import('./order-detail/order-detail.module').then(m => m.OrderDetailModule),
  ImageHeader: () => import('./image-header/image-header.module').then(m => m.ImageHeaderModule),
  ProductDetail: () => import('./product-detail/product-detail.module').then(m =>  m.ProductDetailModule),
  Table: () => import('./table/table.module').then(m => m.TableModule),
  Profile: () => import('./profile/profile.module').then(m => m.ProfileModule),
  FavoriteList: () => import('./favorite/favorite-list.module').then(m => m.FavoriteListModule),
  // PAYMENT METHODS
  Transbank: () => import('./payment-methods/transbank/transbank.module').then(m => m.TransbankModule),
  SocialMedia: ()=> import('./social-media/social-media.module').then( m => m.SocialMediaModule),
  BreadCump: ()=> import('./bread-cump/bread-cump.module').then( m => m.BreadCumpModule),
  SliderPromo: ()=> import('./slider-promo/slider-promo.module').then( m => m.SliderPromoModule),
  GoogleButton: ()=> import('./google-button/google-button.module').then(m => m.GoogleButtonModule),
}
