import {Component, OnDestroy} from '@angular/core';
import {MessagesService, RemovableMessage} from "../shared/services/messages.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnDestroy {

  messages: RemovableMessage[] = [];

  onAddSubs: Subscription | undefined;
  onRemoveSubs: Subscription | undefined;

  constructor(protected messagesService: MessagesService) {

    this.messagesService.onAdd.subscribe(() => {
      this.messages = [ ... this.messagesService.messages ];
    });

    this.messagesService.onRemove.subscribe((id: number) => {
      this.messages = [ ... this.messagesService.messages ];
    });
  }

  removeMessage(id: number | undefined) {

    if (!id) {
      return;
    }

    this.messagesService.remove(id);
  }

  ngOnDestroy() {
    this.onAddSubs?.unsubscribe();
    this.onRemoveSubs?.unsubscribe();
  }
}
