import {ApiConfigInterface} from "../interfaces/api-config.interface";
import {environment} from "../../../environments/environment";

export const ApiConfig: ApiConfigInterface = {
    baseUrl: environment.service?.url,
    routes: {
        // AUTH
        login: 'login',
        logout: 'logout',
        account: 'account',
        signup: 'signup',
        favorite: 'favorite',
        // PRODUCT
        product: 'product',
        bannerFeature: 'banner-feature-pic',
        order:'account-order',
        vehicle:'vehicle',
        addresses: 'account-address',
        cart:'cart',
        shipping:'shipping',
        regions: 'georeference-region',
        // Esto depende del marketplace
        make: 'autopart-finder/field/make/',
        year: 'autopart-finder/cv/VC4-/depot/Wyo-/field/year/make/{make_id}',
        model: 'autopart-finder/field/model/make/{make_id}/year/{year_id}',
        motor: 'autopart-finder/field/motor/make/{make_id}/year/{year_id}/model/{model_id}',
        category: 'autopart-finder/field/category/make/{make_id}/year/{year_id}/model/{model_id}/motor/{motor_id}',
        subcategory: 'autopart-finder/field/subcategory/make/{make_id}/year/{year_id}/model/{model_id}/motor/{motor_id}/category/{category_id}',
        products_category: 'autopart-finder/field/product/model/{model_id}/year/{year_id}/make/{make_id}/motor/{motor_id}/category/{subcategory_id}',
    }
}
