import {EventEmitter, Injectable} from "@angular/core";
import {ProductInterface} from "../interfaces/product.interface";
import {RequestService} from "./request.service";

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    onList: EventEmitter<{ scope: string; list: ProductInterface[] }>
        = new EventEmitter<{ scope: string; list: ProductInterface[] }>()

    constructor(protected requestService: RequestService) {
    }

    async getProduct(id: string): Promise<ProductInterface> {
        return this.requestService.get('product', {
            ignoreToken: true,
            query: `id=${id}`
        });
    }

    listChanged(scope: string, list: ProductInterface[] = []) {
        this.onList.emit({ scope, list });
    }

}
