import { NgModule } from '@angular/core';
import {BrowserModule, provideClientHydration, withHttpTransferCacheOptions} from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BaseElementModule} from "./base-element/base-element.module";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgxsModule} from "@ngxs/store";
import {environment} from "../environments/environment";
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";
import {AuthState} from "./shared/states/auth.state";
import {ModalModule} from "./shared/modal/modal.module";
import {ConnectionServiceModule} from "ngx-connection-service";
import { InterceptorService } from './interceptor/interceptor.service';
import {EntityState} from "./shared/states/entity.state";
import { StorageState } from './shared/states/storage.state';
import {MessagesModule} from "./messages/messages.module";
import {NgxsResetPluginModule} from "ngxs-reset-plugin";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SwiperDirective } from './directives/swiper.directive';

@NgModule({
    declarations: [
    AppComponent,
    SwiperDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxsModule.forRoot([AuthState, EntityState, StorageState], {
            developmentMode: environment['env'] === 'sandbox'
        }),
        NgxsStoragePluginModule.forRoot(),
        NgxsResetPluginModule.forRoot(),
        BaseElementModule,
        FontAwesomeModule,
        ModalModule,
        ConnectionServiceModule,
        MessagesModule,

    ],
    providers: [
        provideClientHydration(
            withHttpTransferCacheOptions({
                includePostRequests: true,
            }),
        ),
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
