import { Injectable } from '@angular/core';
import {CredentialsInterface} from "../interfaces/credentials.interface";
import {UserInterface} from "../interfaces/user.interface";
import * as crypto from 'crypto-js';
import {RequestService} from "./request.service";

type Credentials = 'email' | 'password';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(protected requestService: RequestService) { }

  async login(credentials: CredentialsInterface): Promise<UserInterface | any> {

    return this.requestService.post('login', {
      ignoreToken: true,
      hideLoading: true,
      params: {
        ...credentials, password: crypto.SHA1(credentials.password).toString()
      }
    });
  }

  async logout(): Promise<boolean> {
    return this.requestService.get('logout', {});
  }

  async register(credentials: CredentialsInterface): Promise<any> {
    return this.requestService.post('signup', {
      ignoreToken: false,
      params: {
        ...credentials, password: crypto.SHA1(credentials.password).toString()
      }
    })
  }

  async changeCredential(field: Credentials, value: string): Promise<any>{
    const params: Record<string, string> = {};
    (field==='password') && (value = crypto.SHA1(value).toString())
    
    params[field] = value;

    return this.requestService.post('account', {
      ignoreToken: false,
      params
    })
  }

}
