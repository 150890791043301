import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { AddressResponseInterface, ContactInterface } from '../interfaces/address.interface';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private requestService: RequestService
  ) { }

  list(): Promise<AddressResponseInterface[]>{
    return this.requestService.get('addresses', {ignoreToken:false})
  }
  
  /**
   * 
   * @param address 
   * @returns 
   */
  put(address: AddressResponseInterface | ContactInterface): Promise<any>{
    return this.requestService.put('addresses', {
      ignoreToken: false,
      params: address
    })
  }
  
  delete(id: string): Promise<{status: boolean}>{
    return this.requestService.delete('addresses', {
      ignoreToken: false,
      query: `id=${id}`
    })
  }

}
