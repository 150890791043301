import {AfterViewChecked, Component, ComponentRef, OnDestroy, ViewChild, ViewContainerRef} from '@angular/core';
import {ModalService} from "../services/modal.service";
import {ModalOptions} from "./modal-options";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  host: {
    class: 'app-modal'
  }
})
export class ModalComponent implements OnDestroy {

  options?: ModalOptions;
  _container?: ViewContainerRef | undefined;
  ref?: ComponentRef<any>
  subscriptions: Subscription[] = [];
  @ViewChild('modal_content', { read: ViewContainerRef })
  set container(c: ViewContainerRef | undefined) {
    this._container = c;
    this.create(this.modalService.options);
  }

  constructor(protected modalService: ModalService) {}

  async create(options?: ModalOptions) {
    this.options = options;
    this.subscriptions = [];
    if (!this._container || !this.options?.component)
      return;

    this.ref = this._container.createComponent(this.options.component);

    if (this.options?.input) {
      Object.keys(this.options.input).forEach((k: string) =>
          this.ref?.setInput(k, this.options?.input[k]));
    }

    if (this.options?.output) {
      Object.keys(this.options.output).forEach((k: string) => {
        this.subscriptions.push(this.ref?.instance[k]?.subscribe(this.options?.output[k]));
      });

      this.ref.onDestroy(() => {
        this.subscriptions?.forEach(s => s.unsubscribe());
        this.subscriptions = [];
      });
    }

    this.ref?.changeDetectorRef.detectChanges();
  }

  async close() {
    await this.modalService.dismiss();
  }

  ngOnDestroy() {
    this.ref?.destroy();
  }

}
