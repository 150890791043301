
export class StorageAdd {
  static readonly type = '[Storage] ADD';
  constructor(public type: string, public payload: any | undefined) {}
}

export class StorageDelete {
  static readonly type = '[Storage] DELETE';
  constructor(public type: string, public id: string, public cartId: string = '') {}
}

export class StorageUpdate {
  static readonly type = '[Storage] UPDATE';
  constructor(public type: string, public payload: any | undefined) {}
}