import {EventEmitter, Injectable} from '@angular/core';
import {ModalOptions} from "../modal/modal-options";
import {AppHelper} from "../helpers/app.helper";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  onModalToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  onRequestModal: EventEmitter<string> = new EventEmitter<string>();

  private active: boolean = false;
  public options: ModalOptions | undefined;

  constructor() { }

  async requestModalByName(name: string) {
    this.onRequestModal.emit(name);
  }

  async show(options?: ModalOptions) {

    if (this.active) {
      await this.dismiss();
    }

    await AppHelper.Wait(0);
    this.active = true;
    this.options = options;
    this.onModalToggle.emit(true);
  }

  async dismiss() {
    this.active = false;

    if (this.options?.onClose) {
      await this.options.onClose();
    }

    this.onModalToggle.emit(false);
  }
}
