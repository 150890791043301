import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { VehicleInterface } from '../interfaces/vehicle.interface';

interface VehicleParamInterface {
  brand: string,
  year: string,
  model: string,
  motor: string
}

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(
    private requestService: RequestService
  ) { }

  list(): Promise<VehicleInterface[]>{
    return this.requestService.get('vehicle', {
      ignoreToken: false,
    });
  }

  add(body: VehicleParamInterface ): Promise<VehicleInterface>{

    return this.requestService.post('vehicle', {
      ignoreToken: false,
      params: body 
    })
  }

  delete(id: string): Promise<{status: boolean, message: string}>{
    return this.requestService.delete('vehicle', {
      ignoreToken: false,
      query: `id=${id}`
    })
  }
}
