import {EventEmitter, Injectable} from '@angular/core';
import {MessageInterface} from "../interfaces/message.interface";
import {AppHelper} from "../helpers/app.helper";

export interface RemovableMessage extends MessageInterface {
  status: 'created' | 'showing' | 'hiding';
}

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  messages: RemovableMessage[] = [];

  onAdd: EventEmitter<RemovableMessage> = new EventEmitter<RemovableMessage>();

  onRemove: EventEmitter<number> = new EventEmitter<number>();

  time: number = 5;

  deleteTime: number = 0.5;

  constructor() { }

  async add(message: MessageInterface) {
    message.id = Math.random();
    const rm: RemovableMessage = { ... message, status: 'created' };
    // Add message
    this.messages.push(rm);
    this.onAdd.emit(rm);
    await AppHelper.Wait(0.5);
    rm.status = 'showing';

    // Espera para ocultar
    await AppHelper.Wait(this.time);
    await this.remove(message.id);
  }

  async remove(id: number) {

    const index = this.messages.findIndex(m => m.id === id);

    if (index < 0) {
      return;
    }

    const rm = this.messages[index];
    rm.status = 'hiding';
    // Espera para eliminar
    await AppHelper.Wait(this.deleteTime);
    this.messages.splice(index, 1);
    this.onRemove.emit(rm.id);
  }

}
