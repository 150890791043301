import {EventEmitter, Injectable} from "@angular/core";
import { RequestService } from "./request.service";
import { OrderInterface } from "../interfaces/order.interface";
import { Store } from "@ngxs/store";

@Injectable({
  providedIn: "root",
})
export class OrdersService {

  constructor(
    private store: Store,
    protected requestService: RequestService
  ) {
  }

  async getOrderList({from = '', to = ''}): Promise<OrderInterface> {
      
    return this.requestService.get("order", {
      ignoreToken: false,
      query: `from=${from}&to=${to}`,
    });
  }
  
}
