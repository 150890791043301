import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseElementComponent } from './base-element.component';



@NgModule({
  declarations: [
    BaseElementComponent
  ],
  exports: [
    BaseElementComponent
  ],
  imports: [
    CommonModule
  ]
})
export class BaseElementModule { }
