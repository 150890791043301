import {EventEmitter, Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ApiConfig} from '../config/api.config';
import {LoaderService} from './loader.service';
import {RequestOptionsInterface} from "../interfaces/request.options.interface";
import {AppHelper} from "../helpers/app.helper";
import {ConnectionService, ConnectionState} from "ngx-connection-service";
import {Store} from "@ngxs/store";
import {AuthState} from "../states/auth.state";
import {isPlatformBrowser} from "@angular/common";
import {MessagesService} from "./messages.service";
import {MessageEnum} from "../enums/message.enum";
import {Logout} from "../actions/auth.action";
import {AuthStateInterface} from "../interfaces/auth-state.interface";

@Injectable({
  providedIn: 'root'
})
export class RequestService  {

  onBeginRequest = new EventEmitter();

  onEndRequest: EventEmitter<boolean> = new EventEmitter<boolean>();

  isConnected: boolean = true;

  logout: boolean = false;

  static get host(): string {
    return ApiConfig.baseUrl || '';
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    protected http: HttpClient,
    protected store: Store,
    private messagesService: MessagesService,
    private injector : Injector,
    protected loaderService: LoaderService) 
  {

    if (isPlatformBrowser(this.platformId)) {
      this.injector.get<ConnectionService>(ConnectionService).monitor().subscribe((state: ConnectionState) => {
        this.isConnected = state.hasNetworkConnection && state.hasInternetAccess;
      })
    }

    this.store.select(AuthState.token).subscribe((token: string | undefined) => {
      this.logout = !token;
    })
  }

  /**
   * Check if exists a param into url string. The params is recognized because is enclosed by '{}'
   */
  protected static checkMissingParamOnUrl(url: string): boolean {

    if (!url) {
      return true;
    }

    const indexOpen = url.indexOf('{');
    if (indexOpen < 0) {
      return true;
    }

    const indexClose = url.indexOf('}');
    let param: string;
    if (indexClose < 0) {
      param = 'undefined';
    } else {
      param = url.slice(url.indexOf('{') + 1, url.indexOf('}'));
    }

    throw new Error(`Missing ${param} on route.Check ${url}`);
  }

  /**
   * Create a url from  a named route, url and query params
   */
  public getUrl(routeName: string, params?: any, query?: string): string {

    let url = ApiConfig.routes[routeName];

    if (!url) {
      throw new Error(`Missing ${routeName} on route container. Check your ApiConfig.`);
    }

    if (params) {
      Object.keys(params).forEach( (k) => {
        url = url.replace(`{${k}}`, params[k]);
      });
    }

    RequestService.checkMissingParamOnUrl(url);
    return `${RequestService.host}/${url}${query ? `?${query}` : ''}`;
  }

  get(path: string, options?: RequestOptionsInterface): Promise<any | any[]> {
    return this.request(path, 'GET', options || {});
  }

  post(path: string, options?: RequestOptionsInterface): Promise<any | any[]> {
    return this.request(path, 'POST', options || {});
  }

  put(path: string, options?: RequestOptionsInterface): Promise<any | any[]> {
    return this.request(path, 'PUT', options || {});
  }

  delete(path: string, options?: RequestOptionsInterface): Promise<any | any[]> {
    return this.request(path, 'DELETE', options || {});
  }

  private async request(path: string, method = 'GET', options: RequestOptionsInterface) {

    await AppHelper.Wait(1);
    if (!this.isConnected) {
      return;
    }

    const params = options.params ? options.params : {};
    const url = this.getUrl(path, params, options.query ? options.query : '');
    let headers: any;

    headers = await this.createHeaders(options.ignoreToken);

    const loading  = !options.hideLoading;
    if (loading) {
      this.loaderService.show();
    }

    try {

      this.onBeginRequest.emit();
      const response = await this.callDefault(url, method, options.formData ? options.formData : params,
        headers)?.toPromise().finally(() => loading ? this.loaderService.hide() : '');

      this.logData(options, url, method, params, response);
      this.onEndRequest.emit(false);
      return response;
    } catch (e: any) {

      this.onEndRequest.emit(true);
      this.logData(options, url, method, params, e);

      if (e instanceof HttpErrorResponse && e.status === 401) {

        if (!this.logout) {
          this.logout = true;
          this.messagesService.add({
            type: MessageEnum.ERROR,
            content: 'Su sesión ha terminado. Ingrese nuevamente.',
          });
          this.store.dispatch(new Logout());
        }
      }
      throw new Error(e.message);
    }

  }

  async createHeaders(ignoreToken: boolean | undefined) {

    try {

      const headers: any = {'x-apikey-marketplace': environment.service?.token};
      if (!ignoreToken) {

        const token: string | undefined = this.store.selectSnapshot<AuthStateInterface>((state: any) => state.auth).user?.token;
        if (!token) {
          return undefined;
        }

        headers['x-apikey-deepyshop-user'] = token;
      }

      return new HttpHeaders(headers);
    } catch (e) {

      AppHelper.DevPrint(e);
    }

    return;
  }

  callDefault(url: string, method: string, params: any, headers?: HttpHeaders) {

    switch (method) {
      case 'GET':
        const options = headers ? { headers } : {};
        return this.http.get(url, options);
      case 'POST':
        return this.http.post(url, params, headers ? { headers } : undefined);
      case 'PUT':
        return this.http.put(url, params, headers ? { headers } : undefined);
      case 'DELETE':
        return this.http.delete(url, headers ? { headers } : undefined);
    }

    return null;
  }

  async logData(options: any, url: string, method: string, params: string, response: any) {

    if (environment['env'] === 'live' ) {
      // console.group('RequestService');
      // console.log(`URL: ${url}`);
      // console.log(`METHOD: ${method}`);
      // console.log('params: ', params);
      // console.log('response', await response);
      // console.groupEnd();
    }
  }
}
