import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  onLoaderToggle = new EventEmitter<boolean>();

  private active: boolean = false;

  constructor() { }

  show() {
    if (!this.active) {
      this.active = true;
      this.onLoaderToggle.emit(true);
    }
  }

  hide() {
    if (this.active) {
      this.active = false;
      this.onLoaderToggle.emit(false);
    }
  }
}
