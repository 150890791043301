import {Component, Inject, Injector, OnInit, PLATFORM_ID} from '@angular/core';
import { environment } from "../environments/environment";
import {ModalService} from "./shared/services/modal.service";
import {ConnectionService, ConnectionState} from "ngx-connection-service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  env: string | undefined = environment.marketplace?.environment?.toUpperCase();
  version: string | undefined = environment.marketplace?.version;
  template: any = environment.template;
  layer: any;
  showModal: boolean = false;
  isConnected: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              protected modalService: ModalService, private injector : Injector) {

    this.modalService.onModalToggle.subscribe((active: boolean) => {
      this.showModal = active;
    });

    if (isPlatformBrowser(this.platformId)) {
      this.injector.get<ConnectionService>(ConnectionService).monitor().subscribe((state: ConnectionState) => {
        this.isConnected = state.hasNetworkConnection && state.hasInternetAccess;
      });
    }
  }

  ngOnInit() {
    this.layer = {
      module:  environment.template?.id,
      children: environment['elements'] || []
    }

    if (this.env !== 'live') {
      console.log('ENVIRONMENT', environment)
    }
  }
}
