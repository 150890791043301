import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { CartProduct, CartResponseInterface } from '../interfaces/cart-product.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { AuthStateInterface } from '../interfaces/auth-state.interface';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    protected requestService: RequestService,
    private store: Store,
    protected http: HttpClient,
  ) { }

  async list(): Promise<CartResponseInterface> {
    return this.requestService.get("cart",{ ignoreToken: false});
  }

  async add(itemId: string, qty: string): Promise<any>{
   const params = {
      id: itemId,
      quantity:  qty
   }

    return this.requestService.post("cart", {
      ignoreToken: false,
      hideLoading: false,
      params
    })
  }

  async saveCartAddress(payload: any): Promise<{status: boolean, message: string }>{
    return this.requestService.post('shipping', {
      ignoreToken: false,
      hideLoading: false,
      params: payload
    })
  }

  async delete( id: string, cartId: string): Promise<CartResponseInterface> {
    return this.requestService.delete("cart",{
      ignoreToken: false,
      hideLoading: false,
      query: `id=${id}&model=false&cart_id=${cartId}&variation=false`
    })
  }

  async transBankPay(amount: number): Promise<any> {
    let params = new HttpParams();
    let token: string;
    const url = "https://payment.deepcompany.com/transbank/webpay";

    token = this.store.selectSnapshot<AuthStateInterface>((state: any) => state.auth).user!.token;


    const headers: any = {'x-apikey-marketplace': environment.service?.token};
    headers['x-apikey-deepyshop-user'] = token;

    params = params.append('amount', amount);
    params = params.append('key', "6441e63a0822e7050606a67d" );

    const source$ = this.http.get(`${url}`, {params, headers});
    return await lastValueFrom(source$);
  }
}
