import { environment } from "../../../environments/environment";

export class AppHelper {
  static async Wait(time: number = 5) {
    return new Promise((resolve) => {
      setTimeout(resolve, time * 1000);
    });
  }

  static async ValidateEmail(email: string): Promise<boolean>{
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return !!email.match(pattern);
  }
  
  static async ValidateRut(dni: string): Promise<boolean> {
    let body = dni.slice(0, -1);
    let identifier: string | number = dni.slice(-1).toUpperCase();
    
    if (body.length < 7) { return false }
  
    let sum = 0;
    let mult = 2;

    for (let i = 1; i <= body.length; i++) {
      let index = mult * (dni.charAt( body.length - i ) as unknown as number);
      sum = sum + index;
      (mult < 7)
        ? mult = mult + 1
        : mult = 2; 
    }

    const identifierEsperado = 11 - (sum % 11);
    identifier = identifier == "K" ? 10 : identifier;
    identifier = identifier == 0 ? 11 : identifier;
    
    return (identifierEsperado === identifier);
  }

  static GetUrlSegments(url: string): string[] {
    const path = url.split("?")[0];
    return path.split("/").filter((segment) => segment !== "");
  }

  static GetInRange(value: number, min: 0, max: 12): number {
    return Math.max(min, Math.min(value, max));
  }

  static DevPrint(data: any) {
    if (environment["env"] === "sandbox") {
      if (typeof data === "string") {
        console.warn(data);
      } else {
        console.warn(data?.error ? data.error.detail : data.message);
      }
    }
  }

  static ValueFromPath(entity: any, path: string): any {
    const attrs: string[] = path.split(".");
    let object = { ...entity };
    attrs.forEach((a: string) => {
      if (object && object[a]) {
        object = object[a];
      } else {
        object = undefined;
      }
    });
    return object;
  }
}
