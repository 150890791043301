<div class="modal-container">
    <div class="modal-content {{options?.position || 'center'}}" >
        <ng-template #modal_content></ng-template>
        <div class="close-modal">
            <button id="cancel" type="reset" class="close-btn" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
