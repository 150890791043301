export class EntityById {
  static readonly type = '[Entity] GET BY ID';
  constructor(public type: string, public id: string | undefined) {}
}

export class EntityGetList {
  static readonly type = '[Entity] GET LIST';
  constructor(public type: string, public params:any) {}
}
export class EntityDelete {
  static readonly type = '[Entity] DELETE';
  constructor(public type: string, public id: string, public cartId: string = '') {}
}

export class EntityAdd {
  static readonly type = '[Entity] ADD';
  constructor(public type: string, public payload: any) {}
}
export class EntityUpdate {
  static readonly type = '[Entity] UPDATE';
  constructor(public type: string, public params: {id:string, data: any}) {}
}

