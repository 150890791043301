import { ComponentRef, createNgModule, Injectable, Injector, NgModuleRef, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";
import { LazyModRegistered } from "../../lazy-mod-registered";
import { LazyModuleInterface } from "../interfaces/lazy-module.interface";

export interface ModLoaded {
    ref: NgModuleRef<LazyModuleInterface>,
    components: { [key: string]: { ref: ComponentRef<any>, subscriptions: Subscription[] } }
}

export interface ModulesLoaded {
  [key: string]: ModLoaded
}

@Injectable({
  providedIn: 'root'
})
export class LazyModuleLoader {

  modulesLoaded: ModulesLoaded = {};

  constructor(private injector: Injector) {
  }

  public async loadModule(key: string): Promise<ModLoaded> {

    if (!this.modulesLoaded[key]) {
      const module: any = await this.imports(key);
      this.modulesLoaded[key] = {
        ref: createNgModule(module, this.injector),
        components: {}
      };
    } else {

      console.info(`[LazyModuleLoader] Module for "${key}" was already loaded.`);
    }

    return this.modulesLoaded[key];
  }

  public async loadComponent(container: ViewContainerRef | undefined, moduleKey: string,
                             input: any = {}, output: any = {},
                             componentKey: string = 'default'): Promise<boolean> {

    if (!container) {
      throw new Error(`[LazyModuleLoader] Container not found.`);
    }

    const modLoaded: ModLoaded = await this.loadModule(moduleKey);
    const component = modLoaded.ref.instance.getComponent(componentKey);

    if (!component)
      return false;

    modLoaded.components[componentKey] = {
      ref: container
        .createComponent(component, {
          ngModuleRef: modLoaded.ref
        }),
      subscriptions: []
    }

    // New: Inject style from variable
    modLoaded.components[componentKey].ref.location.nativeElement.style
        = `box-sizing:border-box;${input?.element?.classes?.host || ''}`;

    Object.keys(input).forEach((k: string) =>
      modLoaded.components[componentKey].ref.setInput(k, input[k]));

    Object.keys(output).forEach((k: string) => {

      modLoaded.components[componentKey].subscriptions
        .push(modLoaded.components[componentKey].ref.instance[k]?.subscribe(output[k]));
    });

    modLoaded.components[componentKey].ref.onDestroy(() => {
      this.unsubscribe(moduleKey, componentKey);
    });

    modLoaded.components[componentKey].ref.changeDetectorRef.detectChanges();

    return true;
  }

  unsubscribe(moduleKey: string, componentKey: string = 'default') {

    if (!this.modulesLoaded[moduleKey]) {
      return;
    }

    const modLoaded: ModLoaded = this.modulesLoaded[moduleKey];
    if (modLoaded.components[componentKey]?.subscriptions) {
      modLoaded.components[componentKey]?.subscriptions?.forEach(s => s.unsubscribe());
      modLoaded.components[componentKey].subscriptions = [];
    }
    delete modLoaded.components[componentKey];
  }

  private async imports(key: string): Promise<any> {

    if (!LazyModRegistered[key]) {

      const msg = `[LazyModuleLoader] key ${key} not corresponds to some lazy module.`;
      console.warn(msg);
      throw new Error(msg);
    }

    return await LazyModRegistered[key]();
  }

}
